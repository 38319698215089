import { observable, action, configure, computed } from 'mobx'
import { GetSiteToken } from "../tools/tool"
import history from '../components/history';
configure({ enforceActions: 'always' });

// messageType: error, warning, success
class Config {
    @observable messageInfo = {
        message: "",
        messageType: 'error'
    }
    @observable pageSize = 20;
    @observable defaultOrderItemCount = 8;
    @observable isLoading = [];
    @observable menus = [{
        id: 1,
        parentId: 0,
        name: 'System',
        path: '/admin'
    },
    {
        id: 103,
        parentId: 1,
        name: 'Company List',
        url: '/admin/companylist'
    },
    {
        id: 102,
        parentId: 1,
        name: 'Category List',
        url: '/admin/categories'
    },
    {
        id: 104,
        parentId: 1,
        name: 'User List',
        url: '/admin/userlist'
    },
    {
        id: 105,
        parentId: 1,
        name: 'No Trackings',
        url: '/admin/trackings'
    },
    {
        id: 106,
        parentId: 1,
        name: 'Tasks',
        url: '/admin/tasks'
    },
    {
        id: 107,
        parentId: 1,
        name: 'Import Coupons',
        url: '/admin/importcoupons'
    },
    {
        id: 108,
        parentId: 1,
        name: 'Tracking Fees',
        url: '/admin/trackingfees'
    },
    {
        id: 109,
        parentId: 1,
        name: 'Bulk Helper',
        url: '/admin/bulkhelper'
    },
    {
        id: 110,
        parentId: 1,
        name: 'Logs',
        url: '/admin/logs'
    }, {
        id: 111,
        parentId: 1,
        name: 'Notifications',
        url: '/admin/notifications'
    }, {
        id: 112,
        parentId: 1,
        name: 'UpdateFees',
        url: '/admin/updatexls'
    }, {
        id: 113,
        parentId: 1,
        name: 'Bill List',
        url: '/admin/bills'
    }, {
        id: 200,
        parentId: 0,
        name: 'Process Manage',
        path: '#'
    },
    {
        id: 201,
        parentId: 200,
        name: 'Order Manage',
        url: '/admin/orders'
    }, {
        id: 202,
        parentId: 200,
        name: 'Day Orders',
        url: '/admin/orders/processorders'
    }, {
        id: 205,
        parentId: 200,
        name: 'Order Ship',
        url: '/admin/orders/shiplist'
    }, {
        id: 203,
        parentId: 200,
        name: 'Documents',
        url: '/admin/orders/download'
    }, {
        id: 204,
        parentId: 200,
        name: 'Tag List',
        url: '/admin/tags/list'
    }, {
        id: 300,
        parentId: 0,
        name: 'Message Queues',
        path: '#'
    }, {
        id: 301,
        parentId: 300,
        name: 'Message List',
        url: '/admin/messages/list'
    }, {
        id: 302,
        parentId: 300,
        name: 'Account List',
        url: '/admin/messages/accounts'
    }, {
        id: 303,
        parentId: 300,
        name: 'Template List',
        url: '/admin/messages/templates'
    }, {
        id: 305,
        parentId: 300,
        name: 'SMS List',
        url: '/admin/messages/smslist'
    }, {
        id: 500,
        parentId: 0,
        name: 'Justyling Tools',
        path: '#'
    }, {
        id: 501,
        parentId: 500,
        name: 'Translate List',
        url: '/admin/translate/list/justyling'
    },  {
        id: 502,
        parentId: 500,
        name: 'Attribute Helper',
        url: '/admin/justyling/attributes'
    }, {
        id: 503,
        parentId: 500,
        name: 'Export Emails',
        url: '/admin/justyling/emails'
    }, {
        id: 504,
        parentId: 500,
        name: 'Custom Fonts',
        url: '/admin/justyling/fonts'
    }, {
        id: 600,
        parentId: 0,
        name: 'Etsy Jane Tools',
        path: '#'
    }, {
        id: 601,
        parentId: 600,
        name: 'Translate List',
        url: '/admin/translate/list/etsy'
    }]
    @observable proofmenus = [
        {
            id: 1,
            parentId: 0,
            name: '搜索单号',
            url: '/proof/search'
        }, {
            id: 2,
            parentId: 0,
            name: '待分发',
            url: '/proof/ready'
        },
        {
            id: 3,
            parentId: 0,
            name: '待下载',
            url: '/proof/sent'
        },
        {
            id: 4,
            parentId: 0,
            name: '处理中',
            url: '/proof/processing'
        },
        {
            id: 5,
            parentId: 0,
            name: '待发货',
            url: '/proof/ship'
        },
        {
            id: 6,
            parentId: 0,
            name: '已发货',
            url: '/proof/shipped'
        }]

    @observable vendorMenus = [
        {
            id: 1,
            parentId: 0,
            name: '搜索单号',
            url: '/proof/search'
        }, {
            id: 2,
            parentId: 0,
            name: '待下载',
            url: '/proof/sent'
        },
        {
            id: 3,
            parentId: 0,
            name: '处理中',
            url: '/proof/processing'
        },
        {
            id: 4,
            parentId: 0,
            name: '待发货',
            url: '/proof/ship'
        },
        {
            id: 5,
            parentId: 0,
            name: '已发货',
            url: '/proof/shipped'
        }]

    @observable crystalMenus = [{
        id: 1,
        parentId: 0,
        name: '搜索单号',
        url: '/crystals/search'
    },{
        id: 2,
        parentId: 0,
        name: '待处理',
        url: '/crystals/ready'
    },
    {
        id: 3,
        parentId: 0,
        name: '待下载',
        url: '/crystals/sent'
    },
    {
        id: 4,
        parentId: 0,
        name: '处理中',
        url: '/crystals/processing'
    },
    {
        id: 5,
        parentId: 0,
        name: '待发货',
        url: '/crystals/ship'
    },
    {
        id: 6,
        parentId: 0,
        name: '已发货',
        url: '/crystals/shipped'
    }]
    @observable crystalVendorMenus = [{
        id: 1,
        parentId: 0,
        name: '搜索单号',
        url: '/crystals/search'
    },
    {
        id: 4,
        parentId: 0,
        name: '处理中',
        url: '/crystals/processing'
    },
    {
        id: 5,
        parentId: 0,
        name: '待发货',
        url: '/crystals/ship'
    },
    {
        id: 6,
        parentId: 0,
        name: '已发货',
        url: '/crystals/shipped'
    }]
    @observable justylingMenu = [{
        id: 1,
        parentId: 0,
        name: '搜索单号',
        url: '/justyling/search'
    },{
        id: 2,
        parentId: 0,
        name: '待处理',
        url: '/justyling/ready'
    },
    {
        id: 3,
        parentId: 0,
        name: '已发货',
        url: '/justyling/shipped'
    }]

    @observable commonProofMenu=[{
        id: 1,
        parentId: 0,
        name: '搜索单号',
        url: '/commonproof/search'
    },
    {
        id: 2,
        parentId: 0,
        name: '处理中',
        url: '/commonproof/processing'
    }]
    @observable menuSelection = {
        openKey: null,
        selectedKey: null
    }
    @observable permissions = [];
    @action changeMessage = (msg, errorType) => {
        this.messageInfo = {
            message: msg,
            messageType: errorType
        }
        let that = this;
        if (msg !== '') {
            global.MessageInterval = setTimeout(() => {
                global.MessageInterval = null;
                if (that.messageInfo.message !== '') {
                    that.changeMessage('', 'error');
                }
            }, 3000);
        }
    }
    @action changeLoading = (isLoad) => {
        if (isLoad) {
            this.isLoading.push(1);
        } else {
            this.isLoading.splice(0, 1);
        }
    }


    @action setMenu = (data) => {
        this.menus = data;
    }

    @action SetPermission = (permissions) => {
        this.permissions = permissions;
    }

    @computed get isLogin() {
        let token = GetSiteToken();
        if (token === "") {
            return false;
        } else {
            return true;
        }
    }

    @computed get currentSiteRoot() {
        return this.setBreadcrums();
    }
    setBreadcrums = () => {
        let items = this.menus.filter(x => x.url === history.location.pathname);
        if (items.length > 0) {
            let parent = this.menus.filter(x => x.id === items[0].parentId)[0];
            return {
                id: items[0].id,
                parentId: items[0].parentId,
                parentName: parent === undefined || parent === null ? '' : parent.name,
                parentPath: parent === undefined || parent === null ? '' : parent.path,
                name: items[0].name,
                path: items[0].path,
            }
        }
        return {
            id: 0,
            parentId: 0,
            parentName: '',
            parentPath: '',
            name: "",
            path: "",
        }
        return null;
    }

    @action setCurrentBreadcrums() {
        var bread = this.setBreadcrums();
        if (bread !== null) {
            this.setMenuSelection({
                selectedKey: [bread.id.toString()]
            })
        }
    }

    @action setMenuSelection(info) {
        let newSelection = Object.assign({}, this.menuSelection, info);
        this.menuSelection = newSelection;
    }

}

export default new Config;