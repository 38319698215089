import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import MyLoadingComponent from './components/myloadcomponent'
import './App.css';


const Login = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/login')
})
const BackendLogin = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/backendlogin')
})
const UploadInfo = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/uploadinfo')
})
const JaneUploadInfo = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/janeupload')
})
const UploadPhotos = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/uploadphotos')
})
const SuccessPage = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/success')
})

const Backend = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/backend')
})

const JustylingList = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/justyling/import_export')
})

const JaneJustylingList = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/jane/import_export')
})

const JustBobbleList = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/justbobble/import_export')
})

const ProductManage = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/manage_product')
})

const OrderList = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/orders/list')
})
const ProofSystem = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/proof/home')
})
const OrderInfo = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/proof/orderinfo')
})
const NotificationList = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/notifications')
})

const Polyresion = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/upload_bobble')
})

const PolymerClay = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/upload_ruantao')
})

const DHLForm = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/commons/dhlform')
})
const UnMatchedOrders = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/unmatchedorders')
})
const ProofViewer = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/proofview')
})
const CrystalProof = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/crystals/home')
})
const JustylingRedo = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/justyling/home')
})
const CommonProof = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/commonproof/home')
})
const LabelList = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/orders/label')
})
const BulkEmail = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/tools/bulkemails')
})
const YanWenYunTuShipForm = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/orders/YWYTShiper')
})
const Documents = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/orders/documents')
})
const TranslateList = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/orders/translatelist')
})
function App() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/backendlogin" component={BackendLogin} />
      <Route path="/uploadInfo" component={UploadInfo} />
      <Route path="/janeupload" component={JaneUploadInfo} />
      <Route path="/success/:companyId" component={SuccessPage} />
      <Route path="/admin" component={Backend} />
      <Route path="/justyling/list" component={JustylingList} />
      <Route path="/products" component={ProductManage} />
      <Route path="/upload-photos" component={UploadPhotos} />
      <Route path="/justbobble/list" component={JustBobbleList} />
      <Route path="/jane/list" component={JaneJustylingList} />
      <Route path="/justbobble/products" component={ProductManage} />
      <Route path="/notification/list" component={NotificationList} />
      <Route path="/orders/list" component={OrderList} />
      <Route path="/orders/label" component={LabelList} />
      <Route path="/upload/Polyresion" component={Polyresion} />
      <Route path="/upload/PolymerClay" component={PolymerClay} />
      <Route path="/upload/3DLaserCrystal" component={Polyresion} />
      <Route path="/dhl" component={DHLForm} />
      <Route path="/yuntu" component={YanWenYunTuShipForm} />
      <Route path="/yanwen" component={YanWenYunTuShipForm} />
      <Route path="/unmatch" component={UnMatchedOrders} />
      <Route path="/proof" component={ProofSystem} />
      <Route path="/orderinfo" component={OrderInfo} />
      <Route path="/view-proof" component={ProofViewer} />
      <Route path="/crystals" component={CrystalProof} />
      <Route path="/justyling" component={JustylingRedo} />
      <Route path="/commonproof" component={CommonProof} />
      <Route path="/bulkemails" component={BulkEmail} />
      <Route path="/translates/:type" component={TranslateList} />
      <Route path="/documents" component={Documents} />
    </Switch>
  );
}

export default withRouter(App);
